<template>
  <div>
    <el-form
      ref="form"
      v-cus-loading="form.loading"
      :model="form.model"
      :rules="form.rules"
      label-position="top"
    >
      <el-card shadow="never">
        <el-row>
          <el-col :span="12">
            <span>{{ $tc('voucher', 1) }}</span>
          </el-col>
          <el-col :span="12">
            <el-button
              v-cus-loading="form.loading"
              size="mini"
              type="primary"
              style="float: right"
              @click="onCreateChild"
            >
              {{ $tc('save') }}
            </el-button>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div slot="header">
          <span>{{ $tc('period', 1) }}</span>
        </div>
        <br />
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item
              v-cus-validate-role="[1, 2]"
              :label="$tc('vendor')"
              prop="vendorUserId"
            >
              <select-user-is-seller
                v-model="form.model.vendorUserId"
                :label-all="false"
              ></select-user-is-seller>
            </el-form-item>
            <el-form-item :label="$tc('service')" prop="serviceId">
              <select-service
                v-model="form.model.serviceId"
                :label-all="false"
                @set-selected-entity="onSetService"
              ></select-service>
            </el-form-item>
            <el-form-item :label="$tc('company')" prop="companyKey">
              <select-company-by-service
                v-model="form.model.companyKey"
                :label-all="false"
                :service-id="form.model.serviceId"
                @set-selected="onChangeCompany"
              ></select-company-by-service>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$tc('date')" prop="voucherDate">
              <el-date-picker
                v-model="form.model.voucherDate"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                @change="onChangePeriod(form.model.voucherRecurrence)"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$tc('due')" prop="voucherDateDue">
              <el-date-picker
                v-model="form.model.voucherDateDue"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
              <span class="help">
                <el-tooltip :content="$tc('helpVoucherDateDue')">
                  <i class="fad fa-info-circle"></i>
                </el-tooltip>
              </span>
            </el-form-item>
          </el-col>
          <el-col v-if="roleIsAdministrator" :span="6">
            <el-form-item :label="$tc('start')" prop="voucherDateStart">
              <el-date-picker
                v-model="form.model.voucherDateStart"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col v-if="roleIsAdministrator" :span="6">
            <el-form-item :label="$tc('end')" prop="voucherDateEnd">
              <el-date-picker
                v-model="form.model.voucherDateEnd"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$tc('recurrence')" prop="voucherRecurrence">
              <select-service-recurrence
                v-model="form.model.voucherRecurrence"
                :label-all="false"
                @change="onChangeRecurrence($event)"
              ></select-service-recurrence>
              <div style="text-align: right">
                <small>
                  <em>
                    {{ $tc('period') }}: &nbsp;
                    {{ form.model.voucherDateStart | formatToDate }}</em
                  >
                  -
                  <em>{{ form.model.voucherDateEnd | formatToDate }}</em>
                </small>
              </div>
            </el-form-item>
            <el-form-item :label="$tc('description')" prop="voucherDescription">
              <el-input
                v-model="form.model.voucherDescription"
                type="textarea"
                :placeholder="$tc('description')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card" shadow="false">
        <div slot="header" class="clearfix">
          <span>{{ $tc('company') }}</span>
          <el-button
            size="mini"
            type="primary"
            style="float: right"
            plain
            @click="onClickAddDetail"
          >
            <i class="fad fa-plus"></i>
            &nbsp; {{ $tc('add') }}
          </el-button>
        </div>
        <div v-for="(item, index) in form.model.voucherDetails" :key="index">
          <voucher-detail
            :key="index"
            ref="voucherDetail"
            :index="index"
            :model="item"
            :rules="form.rules.companyKey"
            :service-id="form.model.serviceId"
            :voucher-date="form.model.voucherDate"
            :voucher-recurrence="form.model.voucherRecurrence"
            @bill-add-on="onUpdateModel(index, $event)"
            @bill-feature="onUpdateModel(index, $event)"
            @bill-plan="onUpdateModel(index, $event)"
            @remove="form.model.voucherDetails.splice(index, 1)"
          ></voucher-detail>
        </div>
      </el-card>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :lg="10" :xl="8" :offset="14">
          <el-card shadow="always">
            <div slot="header" class="clearfix">
              <span
                >{{ $tc('total', 2) }}
                <small
                  >/ {{ $tc(toCamelCase(form.model.voucherRecurrence)) }}</small
                >
              </span>
            </div>
            <table width="100%">
              <col style="width: 65%" />
              <col style="width: 35%" />
              <tfoot>
                <tr>
                  <td>{{ $tc('plan', 1) }}</td>
                  <td align="right">
                    {{ voucherPlanPrice | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('feature', 2) }}</td>
                  <td align="right">
                    {{ voucherPlanFeaturePrice | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('addOn') }}</td>
                  <td align="right">
                    {{ voucherAddOnPrice | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('iva') }}</td>
                  <td align="right">
                    {{ voucherIvaValue | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('total') }}</td>
                  <td align="right">
                    {{ voucherAmountTotal | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'
import VueWorker from 'vue-worker'
import { mapActions, mapGetters } from 'vuex'

/* app
-------------------------------------------------- */
import { mapFilters } from '@/filters/map-filters'
import model, {
  calculateVoucherPeriod,
  computedTotalAsync,
  voucherDetailModel,
} from './model'
import rules from './rules'

/* End of app
-------------------------------------------------- */

import create from '@/views/common/create'
import form from '@/views/common/form'

Vue.use(VueWorker)
Vue.use(AsyncComputed)

export default {
  name: 'VoucherCreate',
  extends: create('vouchers', { model }),
  components: {
    voucherDetail: _ => import('./voucher-detail'),
  },
  data() {
    return {
      form: form({ model, rules }),
    }
  },
  methods: {
    ...mapFilters(['toCamelCase']),
    ...mapActions({
      setPlansByService: 'plansByService',
    }),
    onChangePeriod(recurrence) {
      const _this = this
      let months = 0

      switch (recurrence) {
        case 'Yearly':
          months = 12
          break
        case 'Monthly':
          months = 1
          break
        case 'Quarterly':
          months = 3
          break
        case 'Semiannual':
          months = 6
          break
        case 'ThreeYearly':
          months = 36
          break
        case 'EighthYearly':
          months = 96
          break
        default:
          break
      }
      _this.form.model = {
        ..._this.form.model,
        ...calculateVoucherPeriod(_this.form.model.voucherDate, months),
      }
    },
    onClickAddDetail() {
      const _this = this
      _this.form.model.voucherDetails.push(voucherDetailModel())
    },
    onCreateChild() {
      const _this = this

      _this.form.model.voucherTaxBase = _this.voucherTaxBase
      _this.form.model.voucherIvaBase = _this.voucherIvaBase
      _this.form.model.voucherIvaPercent = _this.voucherIvaPercent
      _this.form.model.voucherIvaValue = _this.voucherIvaValue
      _this.form.model.voucherAmount = _this.voucherAmount
      _this.form.model.voucherAmountTotal = _this.voucherAmountTotal

      _this.onSubmit().then(result => {
        this.$confirm(
          `${_this.$tc('sendVoucherEmail')} ?`,
          `${_this.$tc('sendEmail')}`,
          {
            confirmButtonText: _this.$tc('send'),
            cancelButtonText: _this.$tc('cancel'),
            type: 'info',
          },
        ).then(() => {
          _this.form.loading = true
          _this.$http
            .patch(`vouchers/${result.data.id}/VoucherNew/emailNotification`)
            .then(response => {
              _this.$message({
                message: _this.$tc('emailNotificationSuccess'),
                type: 'success',
              })
            })
            .finally(() => {
              _this.form.loading = false
            })
        })
      })
    },
    onChangeCompany(company) {
      const _this = this

      _this.form.model.voucherRecurrence =
        company.companyServiceFrequencyBilling

      _this.onChangeRecurrence(_this.form.model.voucherRecurrence)

      if (_this.form.model.voucherDetails.length === 1) {
        _this.form.model.voucherDetails[0].companyKey = company.companyKey
        _this.$refs.voucherDetail[0].onChangeCompany(company)
      }
    },
    async onChangeRecurrence(recurrence) {
      const _this = this

      _this.onChangePeriod(recurrence)

      if (_this.$refs.voucherDetail.length) {
        for (let index = 0; index < _this.$refs.voucherDetail.length; index++) {
          _this.$refs.voucherDetail[index].onChangePlanRecurrence(recurrence)
        }
      }
    },
    onSetService(service) {
      const _this = this
      _this.$http.get(`plans/findByService/${service.id}`).then(result => {
        _this.setPlansByService(result.data)
        _this.form.model.companyKey = ''
        _this.form.model.voucherDetails.forEach(element => {
          element.companyKey = ''
        })
      })
    },
    onUpdateModel(index, newModel) {
      this.$set(this.form.model.voucherDetails, index, newModel)
    },
  },
  computed: {
    ...mapGetters(['setting']),
    roleIsAdministrator: _this => [1, 2].includes(+_this.setting.roleId),
  },
  asyncComputed: {
    ...computedTotalAsync,
  },
  created() {
    this.form.model.vendorUserId = this.setting.userId
  },
}
</script>
