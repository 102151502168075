export default function ({ model, rules }) {
  return {
    loading: false,
    rules,
    errors: [],
    model: model(),
    reset() {
      this.model = model()
    },
  }
}
