import { fieldRequiredByBlurAndChange } from '@/helpers/validations'

export const voucherDetailRules = {
  companyKey: [fieldRequiredByBlurAndChange],
  planId: [fieldRequiredByBlurAndChange],
  voucherDetailPlanPrice: [fieldRequiredByBlurAndChange],
  voucherDetailType: [fieldRequiredByBlurAndChange],
}

export default {
  companyKey: [fieldRequiredByBlurAndChange],
  planId: [fieldRequiredByBlurAndChange],
  userId: [fieldRequiredByBlurAndChange],
  voucherDate: [fieldRequiredByBlurAndChange],
  serviceId: [fieldRequiredByBlurAndChange],
  voucherStatusReason: [fieldRequiredByBlurAndChange],
  voucherRecurrence: [fieldRequiredByBlurAndChange],
  voucherDetail: voucherDetailRules,
}
