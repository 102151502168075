import { i18n } from '@/i18n'
import view from './view'

export default function (controller, config) {
  return {
    ...view,
    methods: {
      onSubmit(params = {}) {
        const _this = this

        return new Promise((resolve, reject) => {
          _this.$refs.form.validate(valid => {
            if (valid) {
              _this.form.loading = true

              return _this.$http.post(`${controller}/${params.action || ''}`, _this.form.model)
                .then(response => {
                  _this.$message({ type: 'success', message: i18n.tc('processSuccess') })
                  _this.$emit('created', _this.form.model)
                  _this.form.model = config.model()
                  if (_this.onResetSuccess) {
                    _this.onResetSuccess.call(this)
                  }
                  resolve(response)
                })
                .finally(() => { _this.form.loading = false })
            }
            const message = 'Oops, hay datos requeridos que debes ingresar!'
            _this.$message.error(message)
            reject(message)
          })
        }).then()
      },
    },
  }
}
