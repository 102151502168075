import {
  addDays,
  addMonths,
} from '@/helpers/date'

import { formatToDate } from '@/filters'

export const voucherDetailModel = () => ({
  id: '00000000-0000-0000-0000-000000000000',
  companyKey: '',
  voucherId: '00000000-0000-0000-0000-000000000000',
  serviceId: 0,
  planId: '',
  voucherDetailType: '',
  voucherDetailDateStart: '',
  voucherDetailDateEnd: '',
  voucherDetailDateDue: '',
  voucherDetailRecurrence: '',
  voucherDetailDescription: '',
  voucherDetailPlanPrice: '',
  voucherDetailPlanTaxBase: '',
  voucherDetailPlanIvaPercent: '',
  voucherDetailPlanIvaValue: '',
  voucherDetailPlanAmount: '',
  voucherDetailPlanFeaturePrice: '',
  voucherDetailPlanFeatureTaxBase: '',
  voucherDetailPlanFeatureIvaValue: '',
  voucherDetailPlanFeatureAmount: '',
  voucherDetailAddOnPrice: '',
  voucherDetailAddOnTaxBase: '',
  voucherDetailAddOnIvaPercent: '',
  voucherDetailAddOnIvaValue: '',
  voucherDetailAddOnAmountTotal: '',
  voucherDetailDiscount: '',
  voucherDetailTaxBase: '',
  voucherDetailIvaBase: '',
  voucherDetailIvaPercent: '',
  voucherDetailIvaValue: '',
  voucherDetailAmount: '',
  voucherDetailAmountTotal: '',
  voucherDetailAmountPaid: '',
  features: '',
  addOns: '',

  /* ----------  table:plan  ---------- */
  planTaxPercentIncrease: '',
  planTaxPercentIncluded: '',

  /* ----------  custom  ---------- */
  plan: { },
})

export const calculateVoucherPeriod = (date, months) => {
  date = new Date(date)

  const voucherDateStart = new Date(date)
  const voucherDateEnd = addMonths(voucherDateStart, months)

  return {
    voucherDateDue: formatToDate(addDays(date, 10)),
    voucherDateStart: formatToDate(voucherDateStart),
    voucherDateEnd: formatToDate(voucherDateEnd),
  }
}

export const calculateVoucherDetailPeriod = (date, months) => {
  date = new Date(date)

  const voucherDetailDateStart = new Date(date)
  const voucherDetailDateEnd = addMonths(voucherDetailDateStart, months)

  return {
    voucherDetailDateStart: formatToDate(voucherDetailDateStart),
    voucherDetailDateEnd: formatToDate(voucherDetailDateEnd),
  }
}

const periodDate = calculateVoucherPeriod(new Date(), 12)

export default () => ({
  id: '00000000-0000-0000-0000-000000000000',
  companyKey: '',
  serviceId: '',
  personId: 0,
  voucherConsecutive: 0,
  voucherYear: 0,
  voucherMonth: 0,
  voucherDate: formatToDate(new Date()),
  voucherDateDue: periodDate.voucherDateDue,
  voucherDateStart: periodDate.voucherDateStart,
  voucherDateEnd: periodDate.voucherDateEnd,
  voucherStatus: 'Open',
  voucherStatusDate: '',
  voucherStatusReason: '',
  voucherRecurrence: 'Yearly',
  voucherDescription: '',
  voucherDiscount: '',
  voucherReteFuentePercent: '',
  voucherReteFuenteValue: '',
  voucherReteIcaPercent: '',
  voucherReteIcaValue: '',
  voucherReteIvaPercent: '',
  voucherReteIvaValue: '',
  voucherIvaBase: '',
  voucherIvaPercent: '',
  voucherIvaValue: '',
  voucherAmount: '',
  voucherAmountTotal: '',
  voucherAmountPaid: '',
  voucherIsSubscriptedCollect: false,
  voucherDetails: [voucherDetailModel()],
})

export const computedTotalAsync = {
  voucherPlanPrice: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailPlanPrice).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherPlanTaxBase: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailPlanTaxBase).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherPlanIvaPercent: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailPlanIvaPercent).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherPlanIvaValue: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailPlanIvaValue).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherPlanAmount: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailPlanAmount).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherPlanFeaturePrice: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailPlanFeaturePrice).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherPlanFeatureTaxBase: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailPlanFeatureTaxBase).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherPlanFeatureIvaValue: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailPlanFeatureIvaValue).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherPlanFeatureAmount: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailPlanFeatureAmount).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherAddOnPrice: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailAddOnPrice).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherAddOnTaxBase: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailAddOnTaxBase).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherAddOnIvaPercent: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailAddOnIvaPercent).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherAddOnIvaValue: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailAddOnIvaValue).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherAddOnAmountTotal: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailAddOnAmountTotal).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherDiscount: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailDiscount).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherTaxBase: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailTaxBase).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherIvaBase: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailIvaBase).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherIvaPercent: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailIvaPercent).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherIvaValue: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailIvaValue).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherAmount: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailAmount).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
  voucherAmountTotal: {
    get: _this => new Promise(resolve => resolve(
      _this.form.model.voucherDetails.map(item => +item.voucherDetailAmountTotal).reduce((a, b) => a + b, 0),
    )),
    default: 0,
  },
}
